import React from 'react'
import Tile from '../tiles/tile'
import { Link } from 'gatsby'
import LogoSquare from '../../assets/images/logos/square.svg'
import {useSpring, animated} from 'react-spring'

const Logo = (props) => {
  const spring_opts = useSpring({
    opacity: 1,
    from: {opacity: 0},
    delay: (600),
    config: {duration: (2400)}
  });

  const gridStyle = {"gridArea": `${props.row} / ${props.column + 3} / ${props.row + props.height} / ${props.column + props.width + 3}`};

    return (
      <React.Fragment>
        <Tile palette={"generic"} style={{"gridArea": `${props.row} / ${props.column} / ${props.row + 1} / ${props.column + 1}`,}} />
        <Tile palette={"generic"} style={{"gridArea": `${props.row} / ${props.column + 1} / ${props.row + 1} / ${props.column + 2}`}} />
        <Tile palette={"generic"} style={{"gridArea": `${props.row} / ${props.column + 2} / ${props.row + 1} / ${props.column + 3}`}} />

        <Tile palette={"generic"} style={{"gridArea": `${props.row + 1} / ${props.column} / ${props.row + 2} / ${props.column + 1}`}} />
        <Tile palette={"generic"} style={{"gridArea": `${props.row + 1} / ${props.column + 1} / ${props.row + 2} / ${props.column + 2}`}} />
        <Tile palette={"generic"} style={{"gridArea": `${props.row + 1} / ${props.column + 2} / ${props.row + 2} / ${props.column + 3}`}} />

        <animated.div
        style={{...spring_opts, ...gridStyle}}
        className="link link--logo"
        >
          <Link
          style={{"backgroundImage": `url(${LogoSquare})`}}
          to="/about">
            Tales from the Tabletop
          </Link>

        </animated.div>

        <Tile palette={"generic"} style={{"gridArea": `${props.row + 2} / ${props.column + 2} / ${props.row + 3} / ${props.column + 3}`}} />
        <Tile palette={"generic"} style={{"gridArea": `${props.row + 2} / ${props.column + 3} / ${props.row + 3} / ${props.column + 4}`}} />

        <Tile palette={"generic"} style={{"gridArea": `${props.row + 3} / ${props.column + 2} / ${props.row + 4} / ${props.column + 3}`}} />
        <Tile palette={"generic"} style={{"gridArea": `${props.row + 3} / ${props.column + 3} / ${props.row + 4} / ${props.column + 4}`}} />

        <Tile palette={"generic"} style={{"gridArea": `${props.row + 4} / ${props.column + 2} / ${props.row + 5} / ${props.column + 3}`}} />
        <Tile palette={"generic"} style={{"gridArea": `${props.row + 4} / ${props.column + 3} / ${props.row + 5} / ${props.column + 4}`}} />

        <Tile palette={"generic"} style={{"gridArea": `${props.row + 5} / ${props.column + 2} / ${props.row + 6} / ${props.column + 3}`}} />
        <Tile palette={"generic"} style={{"gridArea": `${props.row + 5} / ${props.column + 3} / ${props.row + 6} / ${props.column + 4}`}} />



      </React.Fragment>
    )

}

export default Logo
