import React from 'react'
import Helmet from 'react-helmet'

import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Logo from '../components/media/logo'
import Grid from '../components/tiles/grid'
import MediaQuery from 'react-responsive'
import Tile from '../components/tiles/tile'
import PostList from '../components/PostList'

const IndexPage = props => {
  const { data, pageContext } = props;
  const { edges: posts } = data.allWordpressPost
  console.log(props);
  return (
  <Layout>
    <div>
    <Helmet title={`Tales from the Tabletop`} />
    <div>
      <MediaQuery maxWidth={639}>
        <Grid
          hasFigure={true}
          palette="index"
          ratio={.5}
          size={12*10 - 28}
        >
          <Logo
            column={3}
            height={2}
            row={3}
            width={3}
          />
        </Grid>
      </MediaQuery>

      <MediaQuery minWidth={640} maxWidth={959}>
        <Grid
          hasFigure={true}
          palette="index"
          ratio={.5}
          size={12*20 - 28}
        >
          <Logo
            column={12}
            height={2}
            row={3}
            width={3}
          />
        </Grid>
      </MediaQuery>

      <MediaQuery minWidth={960} maxWidth={1439}>
        <Grid
          hasFigure={true}
          palette="index"
          ratio={.5}
          size={25*25 - 28}
        >
          <Logo
            column={16}
            height={2}
            row={4}
            width={3}
          />
        </Grid>
      </MediaQuery>
      <MediaQuery minWidth={1440}>
        <Grid
          hasFigure={true}
          palette="index"
          ratio={.5}
          size={25*50 - 28}
        >
          <Logo
            column={37}
            height={2}
            row={9}
            width={3}
          />
        </Grid>
      </MediaQuery>
      <PostList posts={posts} title={"Latest Posts"} />
      <Link className="link--as_button" to={`/posts`}>More latest posts</Link>
    </div>
    </div>
  </Layout>
)}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
